<template>
    <div class="box">
    <div class="box-header">
      <h4>{{ pageTitle }}</h4>
    </div>
    <role-form v-if="admin"
        :submit-text="pageTitle"
        :admin="admin"
        :is-edit="false"
        @on-submit="createRole">
    </role-form>
  </div>
</template>

<script>
import RoleForm from '@admin/views/role/_EditForm'
import RoleService from '@admin/services/AdminService'
import flatry from '@admin/utils/flatry'
export default {
  components: {
    RoleForm
  },
  data () {
    return {
      pageTitle: '创建角色',
      admin: null
    }
  },
  async created () {
    const { data } = await flatry(RoleService.createRole())
    if (data) {
      this.admin = data.data
    }
  },
  methods: {
    async createRole (admin, success, callback) {
      const { data } = await flatry(RoleService.createRole(admin))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({ path: '/role/update', query: { id: data.data } })

        success()
      }

      callback()
    }
  }
}
</script>

<style scoped>

</style>
